@import "settings/fonts";
@import "settings/variables";
@import "tools/bootstrap.min";
@import "tools/apprise-v2";
@import "tools/remodal";
@import "tools/remodal-default-theme";
@import "tools/mixins";
@import "generic/common";
@import "base/base";
@import "objects/grid-objects";
@import "objects/header";
@import "objects/new-sampler-slide";
@import "objects/text-slide";
@import "objects/feeling-social-slide";
@import "objects/phase-objects";
@import "objects/banner-objects";
@import "objects/partners-block";
@import "objects/logo-slide";
@import "objects/form-slide";
@import "objects/package-table";
@import "objects/rocket-fuel-slide";
@import "objects/call-bar";
@import "objects/speech-slide";
@import "objects/next-step-slide";
@import "objects/slider-box";
@import "objects/company-info-footer";
@import "objects/overlay-objects";
@import "objects/about-slide-objects";
@import "objects/blog-posts";
@import "objects/package-objects";
@import "objects/testimonial-slide";
@import "objects/team-slide";
@import "objects/contact-page-objects";
@import "objects/service-agreement";
@import "objects/slide-colour-schemes";
@import "objects/process-page-objects";
@import "objects/promotion-page-objects";
@import "objects/service-page-objects";
@import "objects/building-page-objects";
@import "objects/faq-objects";
@import "objects/service-grid";
@import "trumps/wysiwyg";

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.text-center {
  text-align: center !important;
}

.slides > li .slide-inner {
  @extend .clearfix;

  ul {
    list-style-type: disc;
    padding: 0px;
    margin: 0px;

    li {
      margin: 0px 0px 32px 28px;
      text-align: left;
    }

    &.delist {
      li {
        &:before {
          content: '' !important;
          margin: 0px !important;
        }
      }
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
  }
}

.android {
  .slides {
    > li {
      .slide-inner {
        p {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}

.home {
  .slides li {
    &.intro {
      h3 {
        margin: 72px 0 0 0;
      }
    }
  }

}

.happy-indie-line {
  @media(min-width: $mobile-viewport) {
    text-align: right;
  }
}

.play-button {
  @include bg("youtube-green");
  display: inline-block;
  height: 2em;
  width: 2em;
}

.story-line {
  position: absolute;
  bottom: 5px;
  color: white;
  margin-left: 1em;
  font-size: 1.3em;
  cursor: pointer;

  .play-button {
    position: relative;
    margin-right: 1em;
    top: 13px;
  }
}

.pt-1 {
  padding-top: 1em;
}

.pt-2 {
  padding-top: 2em;
}

.green {
  color: $green !important;
}

img.testimonial__image {
  margin-bottom: 0 !important;
}

.black {
  color: black !important;
}

.blog-form {
  max-width: 60em;
  margin: 0 auto;
  padding: 0 15px;
  @extend .row;
  input {
    width: 48%;
    text-align: center;
    margin-right: 1%;
  }

  button {
    width: 48%;
    margin-left: 1%;
    border: 1px solid $pink;
  }

  @media(max-width: $mobile-viewport) {
    input, button {
      width: 100%;
      margin: 0 0 0.7em 0 !important;
    }
  }
}

.txt--white {
  color: white !important;
}

.banner {
  h1 span:nth-child(2), h1 span:nth-child(3) {
    color: #dfdfdf;
    font-size: 1.25em;
    margin: 0 0 32px 0;
  }
}

.banner-object {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: cover;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

// Remove minimum height from footer
.slides li.footer {
  min-height: 0 !important;
}

.tooltip-overlay {

  background: rgba(94, 188, 97, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: none;

  &__message {
    position: absolute;
    background: white;
    color: grey;
    top: 1.5em;
    left: 1.5em;
    right: 1.5em;
    bottom: 1.5em;
    padding: 2em 3em;
    overflow-y: auto;
  }

  &__close {
    display: inline-block;
    position: absolute;
    height: 1em;
    width: 1em;
    top: 3em;
    right: 3em;
    @include bg-icon("green-close");
    z-index: inherit;
    cursor: pointer;
  }

}

.music-ting-icon {
  display: inline-block;
  height: 10em;
  width: 10em;
  @include bg-icon("music-ting");
  margin-bottom: 1em;
}

.music-ting-title {
  max-width: 12em;
  text-align: center;
  margin: 0 auto 1em;
}

.soundcloud-dev-embed {
  //max-height: 380px
}

.music-dev-intro-slide {
  overflow: visible !important;
  margin-bottom: -18em !important;
  position: relative !important;
  z-index: 600;
}

.music-breakdown-slide {
  padding-top: 11em !important;
  width: 100% !important;
}

.funding-ting {
  display: inline-block;
  height: 10em;
  width: 10em;
  @include bg-icon("funding-ting");
  margin-bottom: 1em;
}

.js-more-info-button {
  cursor: pointer;
}

.js-more-info {
  display: none;
}

// new stuffs
.small-page-intro {
  text-align: center;
  color: $green !important;
  margin-bottom: 0;
}

li span.has-tooltip p {
  line-height: 1.5;
}

// Branding circle

.branding-circle {

  &__row {
    background-color: $lightest-green;
    padding: 4em 1em;
  }

  &__image {
    display: block;
    margin: 0 auto;
    position: relative;
    background: transparent url('/assets/images/branding-circle.svg') center no-repeat;
    background-size: contain;
    max-width: 436px;
    width: 100%;
    height: 436px;
  }

  &__text {
    color: $green;
    display: inline-block;
    position: absolute;
    text-align: center;
    cursor: pointer;

    &.brand {
      top: 0;
      left: -2.5em;
    }

    &.music {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: white;
      cursor: auto;
    }

    &.merchandise {
      top: 20%;
      right: -6em;
      color: #83C88D;
    }

    &.online {
      bottom: 0;
      left: -2.5em;
      color: #68BF79;
    }

    &.creative {
      left: 50%;
      transform: translateX(-50%);
      top: -1em;
    }

    &.profile {
      bottom: 3.6em;
      left: -3.25em;
    }

    &.business {
      right: -3.75em;
      bottom: 3.75em;
    }

    &.music-create, &.recording, &.release {
      color: white;
      top: 50%;
      transform: translateY(-50%);
    }

    &.music-create {
      left: 10%;
    }

    &.recording {
      left: 52%;
      transform: translate(-50%, -50%);
    }

    &.release {
      left: 74%;
    }

    @media(max-width: $mobile-viewport) {

      font-size: 1.3em;

      &.online {
        left: -3.5em;
      }

      &.creative {
        font-size: 1em;
      }

      &.business {
        right: -3.75em;
        bottom: 3.65em;
        font-size: 1em;
      }

      &.profile {
        font-size: 1em;
      }

      &.music-create, &.recording, &.release {
        font-size: 1em;
      }
    }

    @media (max-width: 772px) {
      &.brand {
        top: -5%;
        left: -0.75em;
      }

      &.merchandise {
        top: -5%;
        right: -2em;
      }

      &.online {
        left: -5%;
        bottom: -1em;
      }
    }
  }
}

.mentoring-circle {

  &__row {
    background-color: $lightest-green;
    padding: 4em 1em;
  }

  &__image {
    display: block;
    margin: 0 auto;
    position: relative;
    background: transparent url('/assets/images/mentoring-circle.svg') center no-repeat;
    background-size: contain;
    width: 436px;
    height: 436px;
  }

}

.music-circle {
  &__image {
    display: block;
    margin: 4em auto;
    position: relative;
    background: transparent url('/assets/images/music-image.svg') center no-repeat;
    background-size: contain;
    max-width: 881px;
    width: 100%;
    height: 260px;

    @media(max-width: $mobile-viewport) {
      margin: 0em auto;
    }
  }
}

// Take a peek branding

.take-a-peek {
  max-width: 141px !important;
  margin: 0 auto !important;
}

.take-a-peek__text {
  color: $green;
  text-align: center;
  max-width: 300px;
  margin: 1em auto 2em;
}

.branding-box__larger-text {
  position: absolute;
  bottom: 1em;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  opacity: 0;
  transition: all .2s ease-in-out;
}

.branding-box {
  display: inline-block;
  margin: 10px;
  height: 262px;
  width: 262px;
  background: $green url('') center no-repeat;
  background-size: cover;
  position: relative !important;
  cursor: pointer;

  .branding-box__cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    background-color: $green;
    transition: all .2s ease-in-out;
  }

  &:hover {
    .branding-box__cover {
      opacity: .68;
    }

    .branding-box__larger-text {
      opacity: 1;
      z-index: 10;
    }
  }
}

.branding-box.is-opened {
  position: fixed !important;
  height: 750px;
  width: 750px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 100000 !important;
  border: 1px solid white;

  &:hover {
    .branding-box__cover,
    .branding-box__larger-text {
      opacity: 0;
      display: none;
    }
  }

  .clone-close {
    display: inline-block;
    height: 1.5em;
    width: 1.5em;
    background: transparent url('/assets/images/white-close.svg') center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -3em;
    left: 0;
  }
}

// Watch or listen thing

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.listen-or-watch-row {
  margin-bottom: 150px;
}

.media-box {
  display: inline-block;
  max-width: 295px;
  text-align: center;

  margin-right: 10%;

  &:last-of-type {
    margin-right: 0;
  }
}

.media-box__icon {
  display: inline-block;
  height: 141px;
  width: 141px;
  background-size: contain;

  &.video {
    background: transparent url('/assets/images/youtube-ting.svg') left top no-repeat;
  }

  &.music {
    background: transparent url('/assets/images/music-ting.svg') center no-repeat;
  }
}

.media-box__listen {
  display: none;
}

.media-box__listen h2,
.media-box__video h2 {
  color: $green;
  text-align: center;
  max-width: 520px;
  margin: 0 auto 35px;
}

.media-box__button {
  width: 100%;
  font-size: 24px;
  text-transform: none;
}

@media(max-width: $mobile-viewport) {
  .media-box {
    display: block;
    margin: 0 auto 2em !important;
  }
}

.edit-mode {
  .slides > li {
    /*    cursor: pointer;*/

    /*&::after {
      background-image: url('../../admin/assets/images/edit-white.svg');
      background-position: center 48px;
      background-size: 1em;
      background-repeat: no-repeat;
      width: 100%;
      height: 0;
      content: 'Edit this slide';
      position: absolute;
      top: -128px;
      left: 0;
      padding: 96px;
      color: rgba(255, 255, 255, 0);
      text-align: center;
      font-size: 2em;
      font-family: 'Roboto', Arial, Helvetica;
    }

    &:hover {
      &::after {
        top: 0;
        height: 100%;
        background-color: rgba(#3879D6, 0.5);
        color: #fff;
      }
    }*/
  }
}

.circle-diagram__button {
  position: absolute;
  display: inline-block;
  height: 5em;
  width: 14em;
  // background-color: pink;
  z-index: 10;
  cursor: pointer;

  &.communicate {
    top: 18em;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.connect {
    top: 30em;
    left: 30%;
    width: 12em;
  }

  &.release {
    top: 30em;
    left: 53%;
    width: 12em;
  }
}

@media(max-width: 1020px) {
  .circle-diagram__button {

    &.communicate {
      width: 26em;
      top: 23em;
    }

    &.connect {
      top: 46em;
      left: 13%;
      width: 17em;
    }

    &.release {
      top: 46em;
      width: 21em;
    }

  }
}

@media(max-width: $mobile-viewport) {
  .circle-diagram__button {

    &.communicate {
      width: 20em;
      top: 19em;
    }

    &.connect {
      top: 51%;
      left: 13%;
      width: 10em;
    }

    &.release {
      top: 51%;
      width: 11em;
    }

  }
}

.logos__logo .bg-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 32px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.banner h1 {
  margin-top: 72px;
  span {
    line-height: 0.5em;
    display: block;
    font-size: 1.5em;
    margin-bottom: 56px;
    opacity: 0;
    @include transition(opacity 2.5s ease-out)
  }
}

.banner .scroll-hint {
  height: 24px;
  width: 100%;
  background-image: url('/assets/images/down.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
  top: 8px;
  opacity: 0;
  @include transition(opacity 2.5s ease-out)
}

@import "tools/neat/neat";
@import "objects/virtual-management";
@import "objects/team-bio";
@import "objects/what-we-do";