





.slider-box-slide {
	.slide-inner {
		max-width: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}
}




.slider-box {
	height: 700px;
	position: relative;

	&__previous,
	&__next {
		position: absolute;
		top: 50%;
		margin-top: -1.25em;
		height: 2.5em;
		width: 2.5em;
		cursor: pointer;
		z-index: 50;
	}


	&__previous {
		left: 1em;
		@include bg-icon("left_arrow");
	}



	&__next {
		right: 1em;
		@include bg-icon("right_arrow");
	}


	&__image {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-position: center;
		transition: opacity 1.5s ease-in-out;
		background-repeat: no-repeat;


		&:after {
			content: "";
			display: block;
			background: black;
			opacity: .4;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 5;
		}

		&.visible {
			opacity: 1;
		}
	}



	&__image-text-box {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		min-height: 0 !important;
		width: 100%;
		z-index: 10;
		background: transparent !important;

		* {
			color: white !important;
		}


		.new-sampler {
			max-width: 910px;
			margin: 0 auto;
		}
	}
}