body, html {
  font-family: $paragraph-font;
  font-size: $basefont-size;
}

a, a:link, a:hover, a:active, a:visited, a:focus {
  outline: none;
  text-decoration: none;
}

.delist, .delist > li {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  
  &:before
  {
    display: none;
  }
}

select
{
  .mac &, .ios &
  {
    -webkit-appearance: none;
  }
}

label
{
    cursor: pointer
}

.hidden {
  display: none;
}
  
.spacer {
  width: 100%;
  clear: both;
  display: block;
  height: 1px;
}

pre
{
  text-align: left;
  width: 100%;
  padding: 32px;
  background: #222;
  font-family: Monaco;
  color: #E1FFA3;
  max-height: 480px;
  overflow: auto;
  font-size: 0.8em;
  line-height: 1em;
  @include box-sizing();
  z-index: 10000;
  position: relative; 
}

.expand
{
  display: none;
}

.float-left
{
    float: left;
    margin: 0 32px 0 0;
}

h1, h2, h3, h4
{
  font-weight: normal;
}

em, i
{
  font-style: normal;
}

.bounce
{
  @include animation(bounce 0.25s); 
  
  .chrome &
  {
    @include animation(bounce 120s); 
  }
}


@include keyframes(bounce)
{
  0% { @include transform(scale(0.9)) }  
  50% {@include transform(scale(1.05)) }
  100% { @include transform(scale(1)) }
}



.text--green {
  color: #5ebc61 !important;
}

.bg--green {
  background-color: $green;
}

.bg--light-green {
  background-color: $light-green;
}

.text--white {
  color: white !important;
}   