$package-size: 248px;

.packages
{
  text-align: center;
  margin-top: 76px !important;
  
  li
  {
    width: $package-size;
    margin: 0 12px !important;
    display: inline-block;
    vertical-align: top;    
    text-align: center;
            
    &:hover
    {
      em
      {
        @include transform(scale(0.97) translateY(-8px));
        
        .package &
        {
          @include transform(none !important);
        }        
      }      
    }
      
    a, header
    {
      color: $dark;
      text-align: center;
            
      em
      {
        display: block;
        width: $package-size;
        height: $package-size;
        @include background-svg-with-fallback("accelerator");
        margin: 0 0 24px;
        @include transition(all 0.5s ease);
        background-position: center;
        background-size: $package-size;
        background-repeat: no-repeat;
      }
      
      h3
      {
        color: $accelerator-red;
        font-size: 0.85em;
        margin-bottom: 8px;
        text-align: center;
      }
      
      p
      {
        margin: 0px;
        font-size: 0.85em;
      }
    }
    
    &.accelerator
    {
      a, header
      {
        h3
        {
          color: $accelerator-red !important;
        }
      }
    }
    
    &.lift-off
    {
      a, header
      {
        em
        {
          @include background-svg-with-fallback("lift-off");  
        }
        
        h3
        {
          color: $lift-off-blue !important;
        }
      }
    }
    
    &.orbit
    {
      a, header
      {
        em
        {
          @include background-svg-with-fallback("orbit");  
        }
        
        h3
        {
          color: $orbit-orange;
        }
      }
    }    
    
    &.ignition
    {
      a, header
      {
        em
        {
          @include background-svg-with-fallback("ignition");  
        }
        
        h3
        {
          color: $ignition-yellow;
        }
      }
    }     
  }
  
  &.single
  {
    margin-top: 0px !important;
  }
  
  &.extended
  {
    li
    {
      div
      {
        margin: 32px 0;
        border: 3px solid $accelerator-red;
        background: $light;
        padding: 12px;
        font-size: 0.85em;
        line-height: 1em;
        text-align: center;
        position: relative;  
        
        &:before
        {
          content: '';
          display: block;
          width: 100%;
          height: 20px;
          position: absolute;
          top: -20px;
          left: 0;
          @include background-svg-with-fallback("accelerator-speech");
          background-position: bottom center;
          background-repeat: no-repeat;          
        }
        
        h4
        {
          margin: 0 0 24px 0;
          color: $accelerator-red;
        }
        
        p
        {
          margin: 0 0 16px 0;
        }                         
      }
      
      &.lift-off
      {
        div
        {
          border-color: $lift-off-blue;
          
          &:before
          {
            @include background-svg-with-fallback("lift-off-speech");
          }
          
          h4
          {
            color: $lift-off-blue;
          }          
        }        
      }
      
      &.orbit
      {
        div
        {
          border-color: $orbit-orange;
          
          &:before
          {
            @include background-svg-with-fallback("orbit-speech");
          }          
          
          h4
          {
            color: $orbit-orange;
          }          
        }        
      }      
      
      .more
      {
        color: mix($dark, $dark-grey);
        font-size: 0.6em;
        text-align: center;
        width: 100%;
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.32em;
      }        
    }    
  }  
  
  @media(max-width: $mobile-viewport)
  {
    li
    {
      width: 100%;
      margin: 0 0 64px 0 !important;
      
      a, header
      {
        em
        {
          width: 1.75 * 152px;
          height: 1.75 * 152px;
          margin: 0 auto 32px auto;
        }
      }
    }
    
    &.single
    {
      em
      {
        margin: 0 auto;
      }
    }
  }
}



.package__icon span {
  display: inline-block;
  height: 21em;
  width: 100%;


  @media(max-width: $mobile-viewport){
    height: 13em;
  }
}



.package__info {
  position: relative;
  top: 4.5em;

  h2 {
    color: black;
  }
}


.explore-line a {
  margin-right: 1em;
  max-width: 18em;
  text-align: center;

  @media(max-width: $mobile-viewport){
    display: block;
  }
}








// For the slide where package icon is on the right and text on the left
.accelerator-icon,
.liftoff-icon,
.orbit-icon,
.ignition-icon {
  &.icon-right {
    @media(max-width: $mobile-viewport){
      height: 16em * 0.7;
      margin-top: 1.5em;
      top: auto;
    }
  }
}




.icon-right {
  display: inline-block;
  height: 16em;
  width: 100%;
  position: relative;
  top: -1em;
}




.ignition-icon {@include bg("ignition");}
.accelerator-icon { @include bg("accelerator"); }
.liftoff-icon { @include bg("lift-off"); }
.orbit-icon { @include bg("orbit"); }
.ignition-icon { @include bg("ignition"); }




// Logo on /packages
.package-page-logo {
  display: inline-block;
  height: 14em;
  width: 21em;
  @include bg("packages");
}















/* PACKAGE TABLE */

.offer-table {

  th {
    background: #5ebc61;
    color: white;
    border-right: 1px solid white;
    padding: 1em 0.7em;
    text-align: center;
    line-height: 1.3;
  }


  td {
    background: $light-grey;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    padding: 0.7em;
    text-align: left;
    font-weight: bold;
    line-height: 1.3;

    &:first-child {
      color: $green;
    }

    &:last-child {
      border-right: 0;
    }
  }

}