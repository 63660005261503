body.sticky-header {
  header.main {
    position: fixed;
    background: $green;
    height: 70px;
    transition: all 0.15s ease-in-out;

    .logo {
      top: 1px;
      width: 110px;
      height: 64px;
    }

    nav.main {
      font-size: 0.85em;
    }
  }

  .header-inner {
    background: $green;
    transition: all 0.2s ease-in-out;
  }
}

header.main {
  width: 100%;
  height: $header-height;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  // background: $green;

  .header-inner {
    width: 100%;
    max-width: $slide-inner-width-wider;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }

  .logo {
    display: block;
    position: absolute;
    top: 26px;
    left: 26px;
    @include bg('logo');
    width: 160px;
    height: 64px;
    z-index: 1000;
  }

  @media(max-width: $mobile-viewport) {
    background: $green;
    height: 180px !important;

    .header-inner {
      background: $green;
    }

    .logo {
      // Had to absolutely spam importants here because of strict selection before,
      // was a weird glitch that brought wouldn't let scroll below screen, this was
      // foreseeable way around it for near future.
      top: 30px !important;
      height: 100px !important;
      width: 200px !important;
      left: 50% !important;
      margin-left: -100px !important;
    }
  }

  nav.main {
    @media(max-width: $mobile-viewport) {
      display: none;
    }

    line-height: 1.5em;
    width: 100%;
    position: absolute;
    top: 20px;
    padding-top: 16px;
    text-align: right;
  }

  nav.small {
    @media(min-width: $mobile-viewport) {
      display: none;
    }

    background: mix(mix($green, $light), $green);
    width: 50%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 180px;

    > ul {
      > li {
        > a {
          color: $light;
          letter-spacing: 0.32em;
          text-transform: uppercase;
          font-size: 0.85em;
          line-height: 180px;
          vertical-align: middle;
          display: block;

          span {
            @include background-svg-with-fallback("hamburger");
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 22px auto;
            padding-left: 22px + 12px;
          }
        }
        ul {
          display: none;
        }
      }
    }

    &.open {
      > ul {
        > li {

          > a {
            span {
              @include background-svg-with-fallback("close");
            }
          }

          ul {
            background: white;
            display: block;
            position: fixed;
            right: 0px;
            width: 50%;
            padding: 20px 0 0 0;
            min-height: 3000px;

            li {
              text-align: left;

              a {
                color: $green;
                display: block;
                padding: 0 0 0 64px;
              }

              ul {
                width: 100%;
                min-height: 0;
                position: static;
                list-style-type: none;
                display: none;
                padding: 12px 0 32px 0;
                background: #efefef;

                li {
                  background: #efefef;
                }

                a {
                  font-size: 0.85em;
                  padding: 0 0 0 66px;
                }
              }
            }
          }

        }
      }
    }
  }

  .sticky-header & {
    nav.main {
      top: -8px;
    }
  }

  @media(max-width: $mobile-viewport) {
    width: 50%;
    height: 180px;
    position: fixed;
    background-size: 240px auto;

    .nav-open & {
      &:before {
        content: '';
        display: block;
        position: fixed;
        width: 100%;
        height: 3000px;
        background: $dark;
        opacity: 0.5;
      }
    }
  }
}

.sticky-header {

  .slides {
    margin-bottom: 86px !important;
  }

  .call-bar {
    position: fixed;
    bottom: -1px;
    left: 0;
    right: 0;
    z-index: 2000;
  }

  // Hide callbar on viewport because takes up too much
  // if ben confirms can take out some parts of it will make visible again.
  @media(max-width: $mobile-viewport) {
    .slides {
      margin-bottom: 0 !important;
    }

    .call-bar {
      display: none;
    }
  }
}

.desktop-nav {
  > li {
    display: inline-block;
    font-size: 1.25em;
    margin: 0 8px;
    position: relative;

    a {
      color: $light;
      display: block;
      padding: 16px;
      @include transition(opacity 0.25s ease);
      opacity: 0.6;
    }

    &.selected a {
      opacity: 1;
    }

    &:hover {

      a {
        opacity: 1;
      }

      .desktop-nav__sub-menu {
        display: block;
      }
    }
  }
}

.desktop-nav__sub-menu {
  display: none;
  position: absolute;
  top: 49px;
  left: 0;
  text-align: left;
  padding: 0px;
  min-width: 208px;
  font-size: 0.85em;
  background: $white;

  li {
    display: block;
    margin: 0;
    position: relative;

    a {
      color: $green !important;
      padding: 12px 16px;
    }

    &:hover {
      background: $green;

      a {
        color: $light !important;
      }
    }
  }
}

// For subnavs in the main menu eg..
// Promotion > Our Process

.desktop-nav__third-sub-menu {
  padding-left: 0;
  margin-top: -5px;
  display: none;

  li {
    a {
      padding: 5px 5px 5px 36px !important;
    }
  }

}

.mobile-nav {
  li {
    position: relative;

    .dropdown-arrow {
      @include bg-icon("arrow-down");
    }
  }

  &__overview {
    padding-bottom: 20px;

    span {
      color: mix($green, $dark);
      border-bottom: mix($green, $dark);
      padding-bottom: 8px;
    }
  }
}

.mobile-nav__third-submenu {
  li {
    background: #f7f7f7 !important;
  }
}

.dropdown-arrow {
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  @include bg-icon("dropdown-arrow");
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 500;
  cursor: pointer;
}