.faq__title {
	font-family: $paragraph-font;
	margin-bottom: 0.5em;
	font-size: 2.6em;
	margin-top: 1.5em;
	
	&.first
	{
	  margin-top: 0em;
	}
	
}



.faq__question-box {
	border-bottom: 1px solid $green;
	position: relative;
}

.faq__question {
	color: $green;
	margin-bottom: .65em;
	margin-top: .8em;
	font-size: 1em;
	cursor: pointer;
}



.faq__button {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	width: 0.85em;
	height: 0.85em;

	@include bg("arrow-green-right");

	&.is-open {
		@include bg("arrow-green-down");
	}

}




.faq__answer-box {
	padding-top: .5em;
	display: none;	
}