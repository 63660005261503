.virtual-management__plans {
  background: $light-grey;

  + .virtual-management__plans {
    background: mix($light-grey, $white);
  }
}

.virtual-plan {
  text-align: center;
  @extend .col-md-4;
  @extend .col-xs-12;
  position: relative;
  z-index: 10;
  @include transition(transform 0.25s linear);

  &:hover {
    @include transform(scale(0.95));
  }

  h2 {
    color: $black;
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
  }

  ul {
    @extend .delist;
    margin-top: 36px;
    min-height: 240px;

    li {
      text-align: center !important;
      margin: 0 0 12px 0 !important;
    }
  }

  &__footer {
    text-align: center;
    opacity: 0.7;
    position: relative;
    z-index: 10;
  }

  &__price {
    color: $green;
    font-weight: bold;
  }

  &__button {
    a {
      @extend .button;
      color: $white;
      margin-top: 1em;
    }
  }

  &__faux-column {
    @extend .col-md-4;
    @extend .col-md-push-4;
    @extend .hidden-xs;
    background: mix($light-grey, $white);
    position: absolute;
    top: 0;
    height: 100%;
    min-height: 2000px;
    z-index: 1;
  }

  &__image {
    display: block;
    width: 100%;
    height: 72px;
    margin-bottom: 48px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__3-star &__image {
    background-image: url('../images/3-star.svg');
  }

  &__4-star &__image {
    background-image: url('../images/4-star.svg');
  }

  &__5-star &__image {
    background-image: url('../images/5-star.svg');
  }

  @media (max-width: 990px) {
    background: $white;
    @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.1));
    padding: 64px 32px;
    margin: 0 auto 64px;

    &__image {
      height: 114px;
    }
  }
}