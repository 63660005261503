.package-table {
  color: $black;
  font-size: 1.25em;
  width: 100%;
  margin: 0 0 64px 0;

  td, th {

    ul {
      @extend .delist;
      li {
        margin: 0 !important;
        text-align: center !important;
        border-bottom: 1px solid #eee;
        padding: 8px !important;

        &:last-of-type {
          border: 0px
        }
      }
    }

    &.price {
      font-size: 1em !important;
      padding-top: 40px !important;
    }

    .icon {
      display: block;
      width: 100%;
      height: 32px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 0 16px 0;

      &__3-star {
        background-image: url('../images/3-star.svg');
      }

      &__4-star {
        background-image: url('../images/4-star.svg');
      }

      &__5-star {
        background-image: url('../images/5-star.svg');
      }

      &__rocket {
        height: 42px;
      }

      &__rocket-bronze {
        background-image: url('../images/rocket-bronze.svg');
      }

      &__rocket-silver {
        background-image: url('../images/rocket-silver.svg');
      }

      &__rocket-gold {
        background-image: url('../images/rocket-gold.svg');
      }
    }

    small {
      display: block;
      font-size: 0.675em;
      opacity: 0.4;
    }

  }

  @media(max-width: $mobile-viewport) {
    font-size: 1em;
  }

  th {
    text-align: center;
    vertical-align: top;
    width: 20%;
    font-size: 1.2em !important;

    a {
      color: black;
    }

    &:last-of-type {
      padding-right: 0;
    }

    // The spans are the elements containing the text
    span {
      display: block;
      font-size: 1.5em;
    }

    // Em's contain the icons
    em {
      display: inline-block;
      height: 3em;
      width: 3em;
    }

    @media(max-width: $mobile-viewport) {
      font-size: 0.6em;
      padding-right: 1.5em;

      /*&:first-of-type {
        display: none;
      }*/
    }
  }

  tbody tr:nth-child(odd) > td:first-of-type {
    background: darken($light-grey, 3%);
  }

  tbody tr:nth-child(even) > td:first-of-type {
    background: darken($light-grey, 6%);
  }

  tbody tr > td:first-of-type, tfoot tr > td:first-of-type {
    text-align: right;
    font-size: 1em;
    /*
        @media(max-width: $mobile-viewport) {
          display: none;
        }*/
  }

  tfoot tr > td:first-of-type {
    color: black;
    font-size: 1.2em;

    /*  @media(max-width: $mobile-viewport) {
        display: none;
      }*/
  }

  tbody {
    @include box-shadow(0px 0px 32px rgba(0, 0, 0, 0.25));
    tr:nth-child(odd) {
      background: $white;
    }
  }

  tr {

    > td, > th {
      line-height: 1.2em;
      font-size: 0.8em;
      text-align: center;
      padding: 1.5em;
      vertical-align: top;

      img {
        max-width: 112px;
        height: auto;
        width: 100%;
        max-height: 70px;
        margin: 0px;

        display: none !important;
      }

      a {
        color: black;
      }

      /*@media(max-width: $mobile-viewport) {
        padding-right: 1em;
      }*/

      span.has-tooltip {
        display: block;
        margin-left: 0px;
        margin-top: 0.4em;
      }
    }
  }
}

//Mark selected packages as active (controlled via slide class)

.all-active {
  .package-table {
    tr, td, th {
      color: black;
    }
  }
}

.ignition-active {
  .package-table {
    tr > td:nth-child(2) {
      color: black;
    }

    tr > th:nth-child(2) {
      color: black;
    }
  }
}

.accelerator-active {
  .package-table {
    tr > td:nth-child(3) {
      color: black;
    }

    tr > th:nth-child(3) {
      color: black;
    }
  }
}

.liftoff-active {
  .package-table {
    tr > td:nth-child(4) {
      color: black;
    }

    tr > th:nth-child(4) {
      color: black;
    }
  }
}

.orbit-active {
  .package-table {
    tr > td:nth-child(5) {
      color: black;
    }

    tr > th:nth-child(5) {
      color: black;
    }
  }
}

.accelerator-active,
.liftoff-active,
.orbit-active {

  > .slide-inner .row {
    overflow-x: auto;
  }

}

@media (max-width: 990px) {

  .package-table-wrapper {
    position: relative;

    &:after {
      content: '';
      width: 96px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0px;

      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 53%, rgba(255, 255, 255, 1) 100%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 53%, rgba(255, 255, 255, 1) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 53%, rgba(255, 255, 255, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
    }
  }

  .package-table-inner {
    overflow-x: scroll;
    margin-bottom: 64px;

    .package-table {
      min-width: 990px;
      border-right: 96px solid $white;
    }
  }
}