// I know this is a bad name for a file but other ones were already kind of taken :(


.service-block {
	text-align: center;
	margin-bottom: 25px;
	background-color: #F8F6F6;
	padding: 20px;
}



.service-block__image {
	max-width: 98px !important;
	display: inline-block !important;
	margin-bottom: 30px !important;
}



.service-block__text {
	font-size: 28px;
	text-align: left !important;
	margin-bottom: 0 !important;
	height: 4em;
	line-height: 1.3 !important;
}