.promotion-page__title {
  text-align: center !important;
  color: $green !important;
  margin-bottom: 0;
}

.promotion-page__process-title {
  color: $green !important;
}

.promotion-page__process-text {

}

.promotion-page__promote-header {
  margin-bottom: 5em;
}

.promotion-page__promote-circle {
  display: inline-block;
  height: 8em;
  width: 8em;
  background: $pink;
  color: white;
  border-radius: 100%;
  padding-top: 3.1em;
  text-transform: uppercase;
  font-size: 1.2em;
  letter-spacing: .1em;
  text-align: center;
}

.promotion-page__row {
  height: 12em;
  margin-bottom: 4em;
  background: white;
  padding: 1em 1.5em;
  position: relative;
}

.promotion-page__row-triangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-bottom: 1em solid white;
  position: absolute;
  top: -1em;
  left: 50%;
  margin-left: -1em;
}

.promotion-page__row .promotion-page__promote-circle {
  float: left;
  background: none;
  border: 2px solid $green;
  color: $green;
  font-size: .95em;
  height: 10.6em;
  width: 10.6em;
  padding-top: 4.2em;
}

.promotion-page__promote-text {
  padding-left: 12em;
  padding-top: .5em;
}

.promotion-page__promote-text__title {
  color: $green;
}

.promotion-page__promote-text__para {

}

.promotion-page__button {
  margin-top: 24px;
  display: block;
  text-align: center;
  background: $pink;
  color: white !important;
  padding: 1em;
  text-transform: uppercase;
}

@media(max-width: $mobile-viewport) {
  .promotion-page {
    &__row {
      height: auto;

      .promotion-page__promote-circle {
        float: none;
        margin: 0 auto;
        display: block;
        margin-bottom: 1.5em;
      }
    }

    &__promote-text {
      padding: 0;
    }

    &__button {
      margin-bottom: 1em;
    }

  }
}

.circle-diagram {

  &__row {

  }

  &__box {
    background: $lightest-green;
    padding: 12em 0 5em;
    position: relative;
  }

  &__image-box {
    display: block;
    margin: 0 auto;
    max-width: 30em;
    position: relative;

  }

  &__image {
    margin: 0 !important;
    clear: both;
  }

  &__top-left {
    position: absolute;
    top: -4em;
    left: -11em;
    width: 19em;
  }

  &__top-right {
    position: absolute;
    top: -4em;
    right: -6em;
    line-height: 1.8;
  }

  &__bottom-left {
    position: absolute;
    bottom: 3em;
    left: -17em;
    width: 17em;
  }

  &__bottom-right {
    position: absolute;
    bottom: 7.4em;
    right: -17em;
    width: 17em;
  }

  &__list {
  }

  &__list-item {
    color: $green;
    text-align: left;
    line-height: 1.7;
  }

  &__smallprint {
    font-size: .8em;
    color: $green !important;
    padding-left: 23px;
  }

  &__tooltip-text {
    background: white;
    padding: 1em;
    color: grey !important;
    width: 25em;
    font-weight: normal;
    font-size: .8em;
    border-radius: 1em;
    position: absolute;
    bottom: 0;
    left: -11.8em;
    z-index: 100;
    display: none;
    -webkit-box-shadow: 0px 0px 80px -23px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 80px -23px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 80px -23px rgba(0, 0, 0, 1);

    &:after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 1em solid transparent;
      border-right: 1em solid transparent;
      border-top: 1em solid white;
      position: absolute;
      bottom: -.9em;
      left: 50%;
      margin-left: -1em;

    }
  }
}

.circle-diagram__bottom-right {
  .circle-diagram__tooltip-text {
    left: auto;
    right: -.1em;
    border-bottom-right-radius: 0;

    &:after {
      left: auto;
      right: 0;
    }
  }
}

@media(max-width: 1070px) {
  .circle-diagram {
    &__image-box {
      max-width: 27em;
    }
  }
}

@media(max-width: 1020px) {
  .circle-diagram {

    &__box {
      padding: 2em 0;
    }

    &__image-box {
      max-width: none;
      margin: 0 30px;
    }

    &__image {
      padding: 2em 0;
    }

    &__top-left,
    &__top-right,
    &__bottom-left,
    &__bottom-right {
      position: static;
      float: left;
      width: 50%;
      font-size: .8em;
    }

    &__top-left,
    &__bottom-left {
      padding-right: 1em;
    }

    &__top-right,
    &__bottom-right {
      padding-left: 1em;
    }

    &__top-right {
      text-align: right;
    }
  }
}

.has-tooltip {
  display: inline-block;
  height: 1em;
  width: 1em;
  @include bg-icon("tooltip-icon");
  vertical-align: middle;
  margin-left: .4em;
  cursor: pointer;
  position: relative;

  &:hover,
  &.is-showing-tooltip {
    .circle-diagram__tooltip-text {
      display: block;
    }

  }
}