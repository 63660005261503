.team-bios {
  background-color: $light-grey;

  .slide-inner {
    padding-bottom: 0px !important;
  }
}

.team-bio {
  margin: 0 0 32px 0;
  @extend .col-xs-12;
  @extend .col-md-8;
  @extend .col-md-push-2;

  h3 {
    margin: 8px 0 0 0;
  }

  &__image {
    display: block;
    width: 180px;
    height: 180px;
    float: left;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 48px 0 0;
  }
}