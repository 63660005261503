body, html {
  font-family: $paragraph-font;
  color: $dark;
  font-size: $basefont-size;
  text-align: center;
  line-height: 2.2em;
  overflow-x: hidden;
  background-color: $light;
  
  @media(max-width: $mobile-viewport) {
    font-size: 1.25 * $basefont-size;
  }
}



* {
  box-sizing: border-box;

  &:after, &:before {
    box-sizing: border-box;
  }
}



@include selection($pink, $light);

p { margin: 0 0 24px 0; }


a {
  &, &:link { color: #407ABE; }
  &:hover, &:active { color: $green; }
  &:visited { color: #8D69A3; }
}




h1, h2, h3 {
  margin: 0;
  padding: 0;
  line-height: 1.25em;
  
  &.right { text-align: right; }
}

h1 {
 font-family: $heading-font; 
 font-size: 3.25em;
}

h2 {
  font-size: 2.75em;
  color: $green;
  text-align: left;  
  margin: -12px 0px 22px;
  
  @media(max-width: $mobile-viewport) { font-size: 1.5em; }
}

h3 {
  text-align: left;
  font-size: 1.75em;

  strong {
     font-size: 3.25em;
     font-family: $heading-font;
  }
  
  span { color: $green; }
}









input, select, textarea
{
  font-family: $paragraph-font;
  font-size: 1em;
  border: 1px solid $black;
  @include box-sizing();
  padding: 8px 16px;
  margin: 0 0 12px 0;
  min-width: 240px;
  color: $black;
  
  &:focus
  {
    color: $dark;
    border-color: $green;            
  }
  
  &[type=checkbox]
  {
    display: none;
    
    + em
    {
      display: inline-block;   
      width: 20px;
      height: 20px;
      @include box-sizing();
      @include transition(background-position 0.5s ease);
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: center 32px;
      @include background-svg-with-fallback("tick");
      margin: 0 8px 0 0;
      vertical-align: middle;
      background-color: $white;
      border: 1px solid $black;        
      
      + span
      {
        margin-bottom: 16px;
        display: inline;
        font-size: 0.85em;
        font-weight: normal;
      }
      
    } 
    
    &:checked
    {
      + em
      {
        background-color: $light;
        background-position: center;
      }
    }
  }
  
  &[type=radio]
  {
    display: none;
    
    + em
    {
      display: inline-block;   
      width: 36px;
      height: 36px;
      @include border-radius(18px);
      @include box-sizing();      
      margin: 0 8px 0 0;
      vertical-align: middle;
      background-color: $green;
      border: 3px solid $light;        
      position: relative;
      
      + span
      {
        margin-bottom: 16px;
        display: inline;
        color: $light;
        font-size: 0.85em;
      }
      
      &:after
      {
        @include transition(all 0.25s ease);
        content: '';
        width: 0px;
        height: 0px;
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        background: $light;
        opacity: 0;
        @include border-radius(13px);
      }      
      
    } 
    
    &:checked
    {
      + em
      {
        background-color: $green;        
        
        &:after
        {
          width: 24px;
          height: 24px;
          opacity: 1;         
        }
      }
    }
  }  
  
  @media(max-width: $mobile-viewport)
  {
    font-size: 1.5em;
    margin: 0 0 24px 0;
    padding: 16px 32px;
    
    &[type=checkbox]
    {
      + em
      {  
        width: 1.25 * 40px;
        height: 1.25 * 40px;
        background-size: 1.25 * 36px;
      }
    }
  }
}





button, .button
{
  color: $light !important;
  background: $pink;  
  font-family: $paragraph-font;
  font-size: 1.1em;
  display: inline-block;
  padding: 8px 16px;
  margin: 0 0 8px 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: 0px;
  margin: 8px 0 0 0;
  
  &:hover
  {
    background: darken($pink, 10%);    
  }
  
  @media(max-width: $mobile-viewport)
  {
    font-size: 1.5em;
    padding: 16px 32px;
  }  
  
}