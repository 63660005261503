    .text
    {
      &.two-columns
      {
        .slide-inner
        {
          //@include columns(2, 36px);
          
          .column
          {
              width: 50%;
              display: inline-block;
              vertical-align: top;    
              
              &.first
              {
                padding-right: 18px;
              }
              
              &.second
              {
                padding-left: 18px;
              }              
          }
        }

        li
        {
          text-align: justify !important;
        }
        
        @media(max-width: $mobile-viewport)
        {
          .column
          {
            width: 100% !important;
            padding: 0px !important;
            margin-bottom: 48px;
          } 
        }
      }
    }
    