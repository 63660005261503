.logos
  {
    em
    {
      height: 80px;
      display: block;
      
      &.ukti
      {
        @include bg('ukti');
      }
      
      &.bbc
      {
        @include bg('bbc');
      }
      
      &.marshall-arts
      {
        @include bg('marshall-arts');
      }
      
      &.prs
      {
        @include bg('prs');
      }
      
      &.teen-star
      {
        @include bg('teen-star');
      }
      
      &.open-mic-uk
      {
        @include bg('open-mic-uk');
      }                                        
    }
  }



.logos__logo {

  margin-bottom: 2em;

  @media(min-width: $mobile-viewport){
    margin-bottom: 0;
  }
}