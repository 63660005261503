/* ==========================================================================
   Remodal's default mobile first theme
   ========================================================================== */

/* Default theme styles for the background */

.remodal-bg.remodal-is-opening,
.remodal-bg.remodal-is-opened {
  filter: blur(3px);
}

/* Default theme styles of the overlay */

.remodal-overlay {
  background: rgba(43, 46, 56, 0.9);
}

.remodal-overlay.remodal-is-opening,
.remodal-overlay.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodal-overlay.remodal-is-opening {
  animation-name: remodal-overlay-opening-keyframes;
}

.remodal-overlay.remodal-is-closing {
  animation-name: remodal-overlay-closing-keyframes;
}

/* Default theme styles of the wrapper */

.remodal-wrapper {
  padding: 10px 10px 0;
}

/* Default theme styles of the modal dialog */

.remodal {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding: 35px;

  transform: translate3d(0, 0, 0);

  color: #2b2e38;
  background: #fff;
}

.remodal.remodal-is-opening,
.remodal.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodal.remodal-is-opening {
  animation-name: remodal-opening-keyframes;
}

.remodal.remodal-is-closing {
  animation-name: remodal-closing-keyframes;
}

/* Vertical align of the modal dialog */

.remodal,
.remodal-wrapper:after {
  vertical-align: middle;
}

/* Close button */

.remodal-close {
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  overflow: visible;

  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;

  cursor: pointer;
  transition: color 0.2s;
  text-decoration: none;

  color: #95979c;
  border: 0;
  outline: 0;
  background: transparent;
}

.remodal-close:hover,
.remodal-close:focus {
  color: #2b2e38;
}

.remodal-close:before {
  font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
  font-size: 25px;
  line-height: 35px;

  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 35px;

  content: "\00d7";
  text-align: center;
}

/* Dialog buttons */

.remodal-confirm,
.remodal-cancel {
  font: inherit;

  display: inline-block;
  overflow: visible;

  min-width: 110px;
  margin: 0;
  padding: 12px 0;

  cursor: pointer;
  transition: background 0.2s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;

  border: 0;
  outline: 0;
}

.remodal-confirm {
  color: #fff;
  background: #81c784;
}

.remodal-confirm:hover,
.remodal-confirm:focus {
  background: #66bb6a;
}

.remodal-cancel {
  color: #fff;
  background: #e57373;
}

.remodal-cancel:hover,
.remodal-cancel:focus {
  background: #ef5350;
}

/* Remove inner padding and border in Firefox 4+ for the button tag. */

.remodal-confirm::-moz-focus-inner,
.remodal-cancel::-moz-focus-inner,
.remodal-close::-moz-focus-inner {
  padding: 0;

  border: 0;
}

/* Keyframes
   ========================================================================== */

@keyframes remodal-opening-keyframes {
  from {
    transform: scale(1.05);

    opacity: 0;
  }
  to {
    transform: none;

    opacity: 1;
  }
}

@keyframes remodal-closing-keyframes {
  from {
    transform: scale(1);

    opacity: 1;
  }
  to {
    transform: scale(0.95);

    opacity: 0;
  }
}

@keyframes remodal-overlay-opening-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes remodal-overlay-closing-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Media queries
   ========================================================================== */

@media only screen and (min-width: 641px) {
  .remodal {
    max-width: 700px;
  }
}

/* IE8
   ========================================================================== */

.lt-ie9 .remodal-overlay {
  background: #2b2e38;
}

.lt-ie9 .remodal {
  width: 700px;
}
