.our-process__row {
	margin-top: 4em;
}



.our-process {
	&__table {
		width: 	100%;

		td, th {
			text-align: center;	
		}

		td {
			padding: 2.5em 0;
		}
		
	}



	&__table-head {
		th {
			width: 	28.33%;
			color: $green;

			&:first-child {
				width: 	15%;
			}
		}
	}



	&__table-row {
		td:first-child {
			font-weight: bold;
			text-align: left !important;	
		}
	}
}




@media(max-width: $mobile-viewport){
	.our-process__table {
		margin-bottom: 2em;
	}
}


@media(max-width: 650px){
	.our-process__table {
		font-size: .8em;

		td {
			padding: 1.5em 0;
		}
	}
}



@media(max-width: 480px){
	.our-process__table {
		font-size: .6em;

		th {
			width: 30.33%;
		}

		th:first-child {
			width: 8%;
		}

		td {
			padding: 1em 0;
		}
	}
}