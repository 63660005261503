.footer {
  margin-bottom: 0 !important;
}






.footer
{

  min-height: 0px;
  
  &, a
  {
    color: $green;
  }           
  
  .slide-inner
  {
    padding: 0;
    
    > div
    {
      margin: 32px 0;
    }
  }                   
}





.company-info-blocks {
  @extend .row;
  max-width: none !important;
  padding: 0 5em !important;
  font-size: 1.2em;

  @media(max-width: $mobile-viewport){
    padding: 1em 5em !important;
  }
}

.company-info-blocks > div {
  @media(max-width: $mobile-viewport){
    text-align: center;
    line-height: 1em;
    a{
      line-height: 1em;
    }
  }
}