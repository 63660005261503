// Intro table is the first table on the page, 

.service-page__intro-table th {
	width: 20%;
    background: #5ebc61;
    color: white;
    border-right: 1px solid white;
    padding: 1em 0.7em;
    text-align: center;
    line-height: 1.3;
}


.service-page__intro-table {
	margin-bottom: 5em;
	tbody td {
		background: $light-grey;
		border-right: 1px solid white;
		padding: 0.7em;
		text-align: center;
		font-weight: bold;
		line-height: 1.3;

		&:first-child {
			text-align: left !important;
			color: $green;
		}

		&:last-child {
			border-right: 0;
		}
	}
}



@media(max-width: $mobile-viewport){
	.service-page__intro-table {
		font-size: .8em;
	}
}


@media(max-width: 750px){
	.service-page__intro-table {
		font-size: .7em;

		td {
			padding: .6em;
		}
	}
}


@media(max-width: 655px){
	.service-page__intro-table {
		font-size: .55em;

		th {
			padding: 1em .3em;
		}
	}
}





.service-page__table-row {
	margin-bottom: 5em;

	&:last-child {
		margin-bottom: 0;
	}
}




// Info tables are all the tables that follow the intro table

.service-page__info-table {
	margin-bottom: 0;

	tbody td {
		text-align: center;
		padding: 1em;
		line-height: 1.3;
		background: $light-grey;
		border-right: 1px solid white;
		border-bottom: 1px solid white;

		&:first-child {
			color: $green;
			text-align: left;
		}


		&:last-child {
			border-right: 0;
		}
	}


	+ p {
		font-weight: bold;
		font-size: .9em;
	}
} 




.service-page__info-table-head {
	th {
		background: $dark-grey;
		text-align: center;
		border-right: 1px solid white;
		width: 20%;
		padding: 1em;
		line-height: 1.3;

		&:first-child {
			text-align: left !important;
		}

		&:last-child {
			border-right: 0;
		}
	}



	&.is-hidden {
		th {
			padding: 0 !important;
			border: 0 !important;
		}
	}
}



@media(max-width: $mobile-viewport){
	.service-page__info-table {
		font-size: .8em;
	}
}

@media(max-width: 750px){
	.service-page__info-table {
		font-size: .7em;

		tbody td {
			padding: .6em;
		}
	}
}

@media(max-width: 655px){
	.service-page__info-table {
		font-size: .55em;

		th {
			padding: 1em .3em;
		}
	}
}





.service-page__button {
	margin-top: 4em;
	.promotion-page__button {
		max-width: 30em;
		margin: 0 auto;
	}
}














.service-page__tooltip-text {
	background: white;
	padding: 1em;
	color: grey !important;
	width: 25em;
	font-weight: normal;
	font-size: .8em;
	border-radius: 1em;
	position: absolute;
	bottom: 0;
	left: -.1em;
	z-index: 100;
	display: none;
	border-bottom-left-radius: 0;
	-webkit-box-shadow: 0px 0px 80px -23px rgba(0,0,0,1);
	-moz-box-shadow: 0px 0px 80px -23px rgba(0,0,0,1);
	box-shadow: 0px 0px 80px -23px rgba(0,0,0,1);

	&:after {
		content: "";
		display: inline-block;
		width: 0;
		height: 0;
		border-left: 1em solid transparent;
		border-right: 1em solid transparent;
		border-top: 1em solid white;
		position: absolute;
		bottom: -.9em;
		left: 0;
	}
}



.has-tooltip {
	&:hover,
	&.is-showing-tooltip {
		.service-page__tooltip-text {
			display: block;
		}
	}
}