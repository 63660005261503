@mixin font-size($fontsizepx, $important:"")
{
    $size: $fontsizepx / $basefontsize;
  
    font-size: #{$size}em $important;
}

@mixin column-break-inside()
{
    column-break-inside: avoid;
    break-inside: avoid-column;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    display: inline-block;
    @include backface-visibility(); //this fixes issue where transitions would not work within the element
}

@mixin backface-visibility($value: hidden)
{
  -webkit-backface-visibility: $value;    
  -moz-backface-visibility: $value;   
  -ms-backface-visibility: $value;    
  backface-visibility: $value;
}

@mixin box-sizing($value: border-box)
{  
  -moz-box-sizing: $value;
  -webkit-box-sizing: $value;
  box-sizing: $value;
}

@mixin border-radius($radius: 8px) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($value)
{
  box-shadow: $value;
  -moz-box-shadow: $value;
  -webkit-box-shadow: $value;
}

@mixin columns($count, $gap)
{
  -moz-column-count: $count;
  -moz-column-gap: $gap;
  -webkit-column-count: $count;
  -webkit-column-gap: $gap;
  column-count: $count;
  column-gap: $gap;      
}

@mixin hyphens($value: auto)
{
   word-break: break-word;
  
  -moz-hyphens: $value;
  -ms-hyphens: $value;
  -webkit-hyphens: $value;
  hyphens: $value;
}      

@mixin selection($background, $color)
{
  ::-moz-selection
  {
     background: $background;
     color: $color
  }
  
  ::selection
  {
     background: $background;
     color: $color
  }
 
}

@mixin normalise-number-inputs()
{
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  
  input[type=number] {
     @include appearance;
  }
}


@mixin appearance($value: textfield)
{
   -moz-appearance: $value;
   -webkit-appearance: $value;   
   appearance: $value;
}

@mixin keyframes($name)
{
  @-moz-keyframes #{$name}
  {
    @content;
  }

  @-o-keyframes #{$name}
  {
    @content;
  }
  
  @-webkit-keyframes #{$name}
  {
    @content;
  }
  
  @keyframes #{$name}
  {
    @content;
  }    
}


@mixin animation($value)
{
  -moz-animation:    $value;
  -o-animation:      $value;
  -webkit-animation: $value;  
}

@mixin transform($value...)
{  
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  -webkit-transform: $value;
  transform: $value;
}

@mixin transition($value...)
{   
    -moz-transition: $value;
    -o-transition: $value;
    -webkit-transition: $value;
    transition: $value;
}

@mixin background-rgba($r, $g, $b, $a: 1)
{
   background: rgb($r, $g, $b);
   background: rgba($r, $g, $b, $a);   
}


@mixin background-svg-with-fallback($filename, $important:0)
{
  
  $after: "";
  
    @if $important != 0
    {
      $after: " !important";
    }
  
     background-image: url('../images/#{$filename}.svg')#{$after};
          
    .ie7 &, .ie8 &, .firefox3 &, .safari-5 &, .safari-4 &
    {
      background-image: url('../images/#{$filename}.png')#{$after};
      background-size: auto;  
    }       
}

@mixin bg($filename, $important:0)
{
  @include background-svg-with-fallback($filename, $important);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}



@mixin bg-icon($filename) {
   background: transparent url('../images/#{$filename}.svg') center no-repeat;
   background-size: contain;
        
  .ie7 &, .ie8 &, .firefox3 &, .safari-5 &, .safari-4 & {
    background: transparent url('../images/#{$filename}.png') center no-repeat;
    background-size: contain;
  }       
}