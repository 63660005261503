.popular {
  padding: 0 3.5% !important;

  h2 {
    padding-left: 0 !important;
  }
}


.posts h2 {
  padding-left: 3.5%;
  padding-top: 32px !important;
}


.popular .post-block {
  height: 14em;


  @media(max-width: $mobile-viewport){
    margin-bottom: 2em;
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .post__meta {
    bottom: 1em;
    left: 1em;
    right: 1em;

    p {
      margin-bottom: 0;
      color: grey;

      span {
        margin-top: 5px;
      }
    }

    .post-count {
      top: -1em;
    }
  }
}


.popular > div {

  .post-block {
    background: #F6F6F6;
    padding: 15px;
  }


  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
}


.post-column {
  padding: 4em;
  background-size: cover;

  &:nth-of-type(4n+0),
  &:nth-of-type(4n+1){
    .post-block {
      background: $green;
    }
  }

  @media(max-width: $mobile-viewport){
    padding: 1.5em;
  }
}

.post-block {
  position: relative;
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }
}



li.blog-outer .slide-inner {
  max-width: none !important;
  padding: 0 !important;
}


.post__author {
  margin: 0 !important;
  display: inline-block !important;
  float: left;
  width: auto !important;
}


.post__meta {
  position: absolute;
  bottom: 2em;
  left: 2em;
  right: 2em;

    p {
      line-height: 1.2em;
      float: left;
      font-size: 0.9em;

    span {
      display: block;
    }
  }

  @media(max-width: $mobile-viewport){
    font-size: 0.8em;
  }
}









.posts
{
  margin-top: 72 - 48px !important;
  
  li
  {
    background: white;
    color: $dark;
    margin: 0 !important;
    position: relative;
    height: 19em;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    
    .ios &
    {
      min-height: 360px;
    }    
    
    .cover
    {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 100;
    }
            
    .text
    {
      padding: 3em 2em 2em;
      // z-index: 75;
      // position: absolute;
      // bottom: 0;
      // left: 0;
      
      h3
      {
        // color: $light;
        font-size: 1.75em;
      }
      
      p
      {
        // text-transform: uppercase;
        // letter-spacing: 0.32em;
        // color: $light-grey;
        opacity: 0.65;
        // margin: 36px 0 0 0;
        margin: 0;
       font-size: 0.85em;
      }
    }
  }  
  
  @media(max-width: $mobile-viewport)
  {
    li
    {
      // height: auto;

      
      .text
      {
        // position: relative;
        
        h3
        {
          font-size: 1.45em !important;
          
          +p
          {
            span
            {
              display: block;
              height: 0;
              font-size: 0;
            }
          }
        }
      }
    }
  }
}








.blog-outer
{
  
  .archive
  {
    > li
    {
      > span
      {
        color: $green;
        letter-spacing: 0.32em;
        text-transform: uppercase;
        @include background-svg-with-fallback("arrow-down");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 18px;
        padding: 0 24px 0 0;
        cursor: pointer;
      }
      
      > ul
      {
        margin-top: 32px !important;
        display: none;
        
        > li
        {
          margin: 8px 0 0px 0 !important;  
          border-bottom: 1px solid $light-grey;
          padding: 0 0 8px 0;
          
          .date
          {
            width: 192px;
            display: inline-block;  
            margin-right: 8px;
            float: left;         
            
            em
            {
              display: none;                  
            }       
            
            @media(max-width: $mobile-viewport)
            {
              em
              {
                display: block;
              }
              
              i
              {
                display: none;
              }
            }                
          }           
          
          a
          {
            margin: 0 0 0 200px;
            display: block                
          }
        }
      }
    }
    
    &.expanded
    {
      > li
      {
        > span
        {
          @include background-svg-with-fallback("arrow-up");  
        }
        
        > ul
        {
          display: block;
        }
      }
    }
  }
  
}  












.blog-inner
  {
    > li
    {
      &.banner
      {
        background-color: mix($green, $dark);
        position: relative;
                
        .filter
        {
          background-color: darken($green, 40%);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;          
          opacity: 0.65;
        }
        
        h1
        {
          + p
          {
            text-transform: uppercase;
            letter-spacing: 0.32em;
            font-size: 0.85em;
            color: $light-grey;
            opacity: 0.55;
            margin: 36px auto 0 auto;
          }
        }
      }
      
      &.post
      {
        
        a
        {
          &:hover
          {
            text-decoration: underline;
          }
        }
        
        h2
        {
          text-align: left;
          margin: 0 0 16px 0;
          padding: 16px 0 0 0;
        }
        
        blockquote
        {
          color: $darkest-grey;
          font-size: 1em !important;
          border-left: 2px solid $green;
          padding: 18px 24px;
          margin: 0 0 24px 0;
        }
        
        ul
        {
          li
          {
            padding-left: 22px !important;
            
            &:before
            {
              margin-left: -22px !important;
            }
          }
        }
        
        .post-footer
        {
          margin: 64px 0px 72px 0px;
          
          .image
          {
            $image-size: 148px;
            
            width: $image-size;
            height: $image-size;
            @include border-radius($image-size/2);
            background-color: $green;
            background-position: center;
            background-size: cover;
            float: left;
            margin: 0 24px 0 0;
          }
          
          h2
          {
            font-size: 1.25em;
            margin: 0;
            padding: 0px;
          }
          
          strong          
          {
            color: $darkest-grey;
          }
          
          p
          {
            margin: 0;
            line-height: 1.5em;
            text-align: justify;
            margin: 12px 0 0 0;
          }
        }
      }
    }
    
    @media(max-width: $mobile-viewport)
    {
      >li
      {
        &.banner
        {
          h1
          {
            font-size: 2em;
          }                    
        }
        
        &.post
        {
          h2
          {
            margin: 0 0 8px 0 !important;
            padding: 0px;
            font-size: 1em;
          }
          
          .post-footer
          {
            margin: 96px 0 0 0;
            
            p
            {
              font-size: 0.85em;
              margin: 32px 0 0 0;
            }
          }
        }
      }
    }
  }