.phases
{   
  li
  {
    display: inline-block;
    width: 25%;
    margin: 0 0 64px 0 !important;     
    
    a, header
    {
      text-align: center;
      width: 144px;
      height: 144px;
      border: 3px solid $green;
      display: inline-block;
      @include border-radius(72px);
      @include box-sizing();
      line-height: 144px;
      text-transform: uppercase;
      letter-spacing: 0.32em;
      color: $green;
      position: relative;
      @include transition(all 0.5s ease);      
    }
    
    h3
    {
      color: $green;
    }
    
    em
    {
      @include transition(all 0.5s ease);
      @include border-radius(0 0 72px 72px);
      height: 0;
      opacity: 0;
    }
    
    span
    {
      position: relative;
      z-index: 200;
    }
    
    &:hover
    {
      a
      {
        color: $light !important;
      }
      
      em
      {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: $green;
        z-index: 0;
        opacity: 1; 
        @include border-radius(72px);
      }
    }
    
    &.branding
    {
      a, h3
      {
        color: $blue;
        border-color: $blue;
      }
      
      &:hover
      {
        em
        {
          background: $blue;
        }
      }
    }
    
    &.promotion
    {
      a, h3
      {
        color: $pink;
        border-color: $pink;
      }
      
      &:hover
      {
        em
        {
          background: $pink;
        }
      }      
    }    
    
    &.mentoring
    {
      a, h3
      {
        color: $yellow;
        border-color: $yellow;
      }
      
      &:hover
      {
        em
        {
          background: $yellow;
        }
      }      
    }    
    
    @media(max-width: $mobile-viewport)
    {
      width: 50%;
      text-align: center !important;
      
      $size: 1.75 * 144px;
      $radius: 1.75 * 72px;
            
      a, header
      {
        width: $size;
        height: $size;
        @include border-radius($radius);
        margin: 0 auto;
        
        em
        {
          display: none !important;                 
        }        
        
        span
        {
          line-height: 240px;
        }
      }
      
    }
  }
  
  &.overview
  {
    li
    {
      background: $light;
      padding: 32px 36px;
      width: 100%;
      text-align: justify !important;
      position: relative;
      
      a
      {
        float: left;
        margin: 0 48px 0 0;
        
        &.cover
        {
          float: none;
          margin: 0px;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border: 0px;
          @include border-radius(0px);
        }
      }
      
      @media(max-width: $mobile-viewport)
      {
        @include box-sizing();
        text-align: center !important;
        
        a, header
        {      
          float: none !important;
          margin: 0 auto 32px auto !important;
        }
        
        .text
        {
          text-align: justify;
          
          h3
          {
            margin: 0 0 32px 0 !important;
            text-align: center;
          }
        }
      }         
    }
  }
  
  &.single
  {
    li
    {
      width: 100%;
      text-align: center !important;
      
      a, header
      {
        color: $light !important;
        border: 0px;
      }
      
      em
      {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: $green;
        z-index: 0;
        opacity: 1; 
        @include border-radius(72px);
      }
      
      &.branding
      {
        em
        {
          background: $blue;
        }
      }
      
      &.promotion
      {
        em
        {
          background: $pink;
        }
      }
      
      &.mentoring
      {
        em
        {
          background: $yellow;
        }
      }            
    }  
    
    @media(max-width: $mobile-viewport)
    {
      li
      {
        em
        {
          display: block !important;
          @include border-radius(144px);
        }
      }
    }  
  }
}