.wysiwyg-editor {
  text-align: justify;
  padding: 16px !important;
  min-width: 0px;
  position: relative;

  h1 {
    span {
      display: block;
      text-align: center;
    }
  }

  .banner, .slider-box__image-text-box {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    transform: none !important;
    background: grey !important;

    span {
      opacity: 1;
    }
  }

  img {
    max-width: 100%;
  }

  .faq__answer-box {
    display: block;
  }

  .browser__window, img, .own-words__image, .quote__client, .page-image__image-box, .rm-logo, .bg-image, .js-bg-image {
    cursor: crosshair;

    &:hover {
      box-shadow: 0px 0px 64px #3879D6;
    }

    &.active-element {
      box-shadow: 0px 0px 64px yellow;
    }
  }

  .past-project__slide-image img {
    position: relative;
    z-index: 10000;
  }

  .rm-logo, .bg-image, .js-bg-image {
    cursor: crosshair;
    display: inline-block;
    min-width: 100px;
    min-height: 50px;
  }

  .container-fluid {
    border: 1px dashed blue;
    padding: 4px;
  }

  .row {
    border: 1px dashed red;
    padding: 4px;

    > div {
      border: 1px dashed green;
      padding: 4px;
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }

  .js-bg-image, img /*Any click-to-change image*/
  {
    cursor: crosshair;
    border: 4px solid $dark-grey;

    &:hover {
      border-color: $light-grey;
    }

    &.active-element {
      border-color: $light-grey;
    }
  }

}