.slide-inner {
  @media(max-width: $mobile-viewport) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

// The all encompassing ul containing the li created in the CMS
.slides {
  // margin-top on smaller screens so that slides actually start
  // below the fixed header 
  margin-top: 180px;
  margin-bottom: 0;
  text-align: justify;

  @media(min-width: $mobile-viewport) {
    margin-top: 0;
  }

}

// The list element that contains the content created in the backend
// for each slide
.slides > li {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  clear: both;

  @media(max-width: $mobile-viewport) {
    background-position-y: 120px !important;
  }

  // If the body class has any of the following then don't have background
  // fixed
  .chrome &,
  .ios &,
  .android & {
    background-attachment: scroll;
  }

  // If the li has the class filter, then make the .slide-inner pushed in the
  // z-index so it's not covered over. Then use the after pseudo element to
  // stretch over the slide
  &.filter {
    .slide-inner {
      position: relative;
      z-index: 999;
    }

    &:after {
      content: '';
      background: $dark;
      opacity: 0.3;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  // If .page-not-found added to body class then change colour of
  // following elements within the li
  .page-not-found & {
    &, h2, a {
      color: $light;
    }
    a {
      text-decoration: underline;
    }
  }

}

.slide-inner {
  padding: 96px 15px;

  @media(min-width: $mobile-viewport) {
    max-width: $slide-inner-width;
    margin: 0 auto;
    padding: 84px 15px;
    position: relative;
    @include transition(all 1s ease);
  }
}