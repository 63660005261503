.apprise-overlay
{
  background: rgba(43, 46, 56, 1);
  z-index: 99999;
}

.apprise
{
  background: $light !important;
  border: 0px !important;
  @include box-shadow(none !important);
  color: $green !important;
  text-align: left;
  padding: 32px;
  @include box-sizing();
  z-index: 999999;

  .apprise-content
  {  
    font-family: $paragraph-font !important;
    font-size: 1.1em !important;
    line-height: 2em;
    color: $black;  
  }
 
  .apprise-buttons
  {
    background: $light !important;
    border: 0px !important;
    box-shadow: none !important;
    
    button
    {
     background: $green !important;
     text-shadow: none !important;
     border: 0px !important;
     @include border-radius(0 !important);
     font-family: $paragraph-font;
     color: $white;
     font-size: 1.25em !important;
     @include box-sizing();
     padding: 8px 16px !important;
     margin: 0 0 8px 0 !important;
     text-transform: uppercase;
     letter-spacing: 0.32em;
      
     &:hover
     {
        @include box-shadow(none !important);
        background: $green !important;
        color: $light;
     }  
    }
  }  
  
}

 


.overlay
{
  background: rgba($green, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  
  .overlay-message-wrapper
  {
    position: fixed;
    top: 320px;
    width: 100%;
    text-align: center;
    
    .overlay-message-inner
    {
      background: $light;
      color: $green;
      font-family: $heading-font;
      font-size: 3.25em;
      width: 25%;
      margin: 0 auto;
      padding: 64px 64px 64px 128px;        
      background-image: url('../images/loader.gif');
      background-position: 64px;
      background-repeat: no-repeat;
    }
  }
  

}





.remodal
{
  max-width: 30.4%;
  
  form
  {
    &, input
    {
      width: 100%;
    }
  }
  
  .closer
  {
    cursor: pointer;
    color: $darkest-grey;
    margin: 16px 0 0 0;
    display: inline-block;
    font-size: 0.85em
  }
}