.rf-testimonial-slide {
  // padding-bottom: 84px !important;
  // Above fixes the overlap that happens when loads of text in testimonial postit note
}




.rf-testimonial {

  &__image-wrapper { margin-bottom: 4em; }

  &__image {
    height: 640px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }


  &__quote {
    background: lighten($light-green, 10%);
    width: 90%;
    position: relative;
    top: -121px;
    right: -12%;
    padding: 36px 48px 10px 48px;
    font-family: $handwriting-font;
    font-size: 4.5em;
    line-height: 0.8em;
    border: 0;

    @media(min-width: $mobile-viewport){
      position: absolute;
      width: 75%;
      top: 57%;
      right: 15px;
    }

  }

  &__quote-footer {
    color: $black;
    font-size: 1em;
    line-height: 1em;

    &:before { display: none !important; }
  }


  &__title {
    font-size: 2.75em;
    color: #5ebc61;
    margin: -12px 0 22px;
    padding: 0;
    line-height: 1.25;
  }


  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }



  &__link {
    cursor: pointer;
  }

}



.has-padded-testimonial {
  .rf-testimonial-slide {
    padding-bottom: 84px !important;
  }
}