@font-face {
  font-family: 'aleobold';
  src: url('../fonts/Aleo-Bold-webfont.eot');
  src: url('../fonts/Aleo-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Aleo-Bold-webfont.woff') format('woff'),
  url('../fonts/Aleo-Bold-webfont.ttf') format('truetype'),
  url('../fonts/Aleo-Bold-webfont.svg#aleobold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'chivoregular';
  src: url('../fonts/chivo-regular-webfont.eot');
  src: url('../fonts/chivo-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/chivo-regular-webfont.woff2') format('woff2'),
  url('../fonts/chivo-regular-webfont.woff') format('woff'),
  url('../fonts/chivo-regular-webfont.ttf') format('truetype'),
  url('../fonts/chivo-regular-webfont.svg#chivoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Jellyka - Estryas Handwriting';
  src: url('../fonts/Jellyka-EstryasHandwriting.eot');
  src: url('../fonts/Jellyka-EstryasHandwriting.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Jellyka-EstryasHandwriting.woff') format('woff'),
  url('../fonts/Jellyka-EstryasHandwriting.ttf') format('truetype'),
  url('../fonts/Jellyka-EstryasHandwriting.svg#Jellyka-EstryasHandwriting') format('svg');
  font-weight: normal;
  font-style: normal;
}
