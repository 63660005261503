// New Sampler Section

.new-sampler {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
  background: #F7F6F6;
  
  &__text {
    color: $green;
    text-align: left !important;
    margin-bottom: 40px;
  }


  &__cover-link {
    position: absolute;
    top: -2em;
    bottom: -2em;
    width: 200%;
    left: -50%;

  }
  
  &__logo {
    background: transparent url("../images/square-logo.png") center center no-repeat;
    background-size: contain;
    display: inline-block;
    height: 375px;
    width: 375px;
    float: left;
    margin-right: 30px;
    
      @media(max-width: $mobile-viewport){
        float: none;
        max-width: 375px;
        width: auto;
        display: block;
      }
  }
  
  &__link {
    color: $green;

    a {
      display: inline-block;
      width: 70px;
      height: 70px;
      background: transparent url("../images/play-logo.png") center center no-repeat;
      background-size: contain;
      margin-right: 1em;
      vertical-align: middle;
    }
  }
  
  
  &__text-block {
    display: inline-block;
    max-width: 475px;
  }
  
  
  .slide-inner { max-width: 910px !important; }
  
}