   
// .slide-inner {



    .green
    {
      background: $green;
      
      h2
      {
        color: $light !important;
      }


      p {
        color: white !important;
      }
    }



    
    .light-grey
    {
      background: $light-grey;
      
      + .light-grey
      {
        background: $light;
      }
      
      h2
      {
        color: $dark;
      }      
    }    




    
    .light
    {
      background: $light;
      
      h2
      {
        color: $dark;
      }
           
    } 
    
    .light, .testimonial
    {
      + .footer
      {
        background: $light-grey;
      }
    }



// }