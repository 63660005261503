.partners
{
  margin-top: 56px !important;
  text-align: center;
  max-width: 520px;
  margin: 40px auto 0 !important;
  
  li
  {
    display: inline-block;
    width: 33%;
    text-align: center !important;
    @include box-sizing();
    vertical-align: middle;
    padding: 0 24px;
    margin: 0 0 0 !important;
    
    img
    {
      max-width: 100%;
      margin-bottom: 65px !important;
    }
  }

  @media(max-width: $mobile-viewport)
  {
    li
    {
      width: 50%;
    }
  }

}