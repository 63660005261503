// Can't actually find where this is.
// But it's here if we need it.

.speech
{  
  background: $light;
  padding: 32px;
  margin: 0 auto 24px auto !important;
  position: relative;
  display: inline-block;
  width: 100%;
  
  ul
  {
    li
    {
      margin-bottom: 0 !important;
      margin-left: 28px !important;
    }
  }
  
  h3
  {
    color: $green;
    font-size: 1em;
    margin-bottom: 12px;
    text-transform: uppercase;
    
    @media(max-width: $mobile-viewport)
    {
      text-align: center;
      margin: 24px auto 32px !important;
    }
  }
  
  &.first
  {
    margin-top: 32px;
    
    &:before
    {
      content: '';
      position: absolute;
      top: -32px;
      left: 0;
      width: 100%;
      height: 32px;
      @include background-svg-with-fallback("speech");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: contain;      
    }  
  }   
 
  
  .accelerator &
  {
    ul
    {
      li
      {
        > span
        {
          color: $accelerator-red;
        }
      }
    }
  }
  
  @media(max-width: $mobile-viewport)
  {
    max-width: 100% !important;
    
    h3
    {
      strong
      {
        display: block;
        margin: 24px 0 32px 0
      }
      
      width: 75%;
      margin: 0 auto;
    }
  }
}