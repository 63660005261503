.contact
{
  .two-columns
  {
    h2
    {
      font-size: 0.85em;
      text-align: left;
      margin: 0 0 8px 0;      
    }
    
    a
    {
      color: $dark;
    }
  }  
}




ul.social
{
  text-align: center;
  margin: 24px auto 0 auto !important;
  
  li
  {
    width: 35px;
    height: 36px;
    display: inline-block;
    @include background-svg-with-fallback("facebook-green");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    vertical-align: middle;
    margin: 0 14px !important;
    
    a
    {
      display: block;
      width: 100%;
      height: 100%;
    }
    
    &.twitter
    {
      @include background-svg-with-fallback("twitter-green");     
    }
    
    &.youtube
    {
      @include background-svg-with-fallback("youtube-green");      
    }
    
    &.linkedin
    {
      @include background-svg-with-fallback("linked-in-green");      
    }
    
    &.googleplus
    {
      @include background-svg-with-fallback("google-green");      
    }
    
    &.pinterest
    {
      @include background-svg-with-fallback("pinterest-green");      
    }
    
    &.instagram
    {
      @include background-svg-with-fallback("instagram-green");      
    }            
  }  
}




.map {
  background: $green;
  min-height: 448px !important;  
}