.apprise-overlay {
  background-color: rgb(255,255,255);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
}

div.apprise {
	background: #fff;
	border: 1px solid #aaa;
	box-shadow: 0px 2px 15px rgba(0,0,0,0.2);
		-mox-box-shadow: 0px 2px 15px rgba(0,0,0,0.2);
		-webkit-box-shadow: 0px 2px 15px rgba(0,0,0,0.2);
	color: #111;
	display: none;
	font-family: Arial, sans-serif;
	font-size: 14px;
	left: 40%;
	max-height: 90%;
	overflow: hidden;
	position: fixed;
	top: -100%;
	width: 20%;
}

div.apprise .apprise-inner {
	padding: 20px;
}

div.apprise .apprise-input {
	margin-top: 10px;
	padding: 10px 0;
}

div.apprise .apprise-input input {
	border: 1px solid rgba(0,0,0,0.3);
	border-radius: 2px;
		-moz-border-radius: 2px;
		-webkit-border-radius: 2px;
	box-shadow: inset 0px 0px 5px rgba(0,0,0,0.1);
		-mox-box-shadow: inset 0px 0px 5px rgba(0,0,0,0.1);
		-webkit-box-shadow: inset 0px 0px 5px rgba(0,0,0,0.1);
	display: block;
	font-size: 13px;
	margin: 0 auto;
	padding: 5px 10px;
	width: 90%;
}

div.apprise .apprise-input input:focus {
	border-color: #01AEF0;
	outline: none;
}

div.apprise .apprise-buttons {
	background: #eee;
	border-top: 1px solid #aaa;
	box-shadow: inset 0px 1px 0px #fff;
		-moz-box-shadow: inset 0px 1px 0px #fff;
		-webkit-box-shadow: inset 0px 1px 0px #fff;
	padding: 10px 20px;
	text-align: right;
}

div.apprise .apprise-buttons button {
	background: #ededed;
	border: 1px solid rgba(0,0,0,0.5);
	border-radius: 2px;
		-moz-border-radius: 2px;
		-webkit-border-radius: 2px;
	box-shadow: inset 0px 1px 0px rgba(255,255,255,0.5);
		-mox-box-shadow: inset 0px 1px 0px rgba(255,255,255,0.5);
		-webkit-box-shadow: inset 0px 1px 0px rgba(255,255,255,0.5);
	cursor: pointer;
	font-size: 13px;
	margin: 0 2px;
	overflow: hidden;
	padding: 5px 12px;
	text-shadow: 0px 1px 0px rgba(255,255,255,0.8);
}

div.apprise .apprise-buttons button.blue {
	background: #01AEF0;
}

div.apprise .apprise-buttons button.red {
	background: #D23A30;
}

div.apprise .apprise-buttons button.blue, div.apprise .apprise-buttons button.red {
	color: #fff;
	text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
}

div.apprise .apprise-buttons button:hover {
	box-shadow: inset 0px 1px 0px rgba(255,255,255,0.5), 0px 1px 3px rgba(0,0,0,0.4);
		-moz-box-shadow: inset 0px 1px 0px rgba(255,255,255,0.5), 0px 1px 3px rgba(0,0,0,0.4);
		-webkit-box-shadow: inset 0px 1px 0px rgba(255,255,255,0.5), 0px 1px 3px rgba(0,0,0,0.4);
}

div.apprise .apprise-buttons button:active {
	box-shadow: inset 0px 1px 2px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,0.8);
		-moz-box-shadow: inset 0px 1px 2px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,0.8);
		-webkit-box-shadow: inset 0px 1px 2px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,0.8);
}