.service-agreement
  {
    .fixed-width
    {
      max-width: $slide-inner-width !important;
      
      iframe
      {
        width: 100%;
        border: 2px solid mix($dark, $light);
        height: 800px;
      }  
    }
  }