$neat-grid: (
        columns: 12,
        gutter: 48px,
);

.what-we-do-slide
{
  background: $light-grey;
}

.what-we-do {
  &__item {
    @include grid-column(4);

    @media (max-width: 990px) {
      @include grid-column(12);
      margin-bottom: 96px;
    }
  }
}