.call-bar
{
  background: $green;
  overflow: hidden;
  @include transition(all 0.2s ease-out);

  .button {
    display: inline-block;
    width: auto;
    background: none;
    border: 2px solid white;
    padding: 13px 55px;
    margin: 0 !important;
    font-size: 14px;
  }
    
  .inner
  {
    max-width: $slide-inner-width-wider;
    margin: 0 auto;
    color: $white;
    padding: 11px 0;
    height: 86px;
    @include transition(all 0.2s ease-out);  

    
    a
    {
      color: $lightest-green;
      @include transition(color 0.5s ease-out);
      
      &:hover
      {
        color: $light;
      }
    }
    
    > div {
      margin-top: 15px;

      &:first-of-type {
        margin-top: 0;
      }
    }

  }
}