.building-page__table-row {

}




.building-page__table {
	tbody td {
		text-align: center;
		padding: 1em;
		border-right: 1px solid white;
		border-bottom: 1px solid white;
		background: #f7f6f6;
		line-height: 1.3;
	}
}




.building-page__shaded-row {
	td {
		background: #ebebeb !important;
	}
}




.building-page__table-head {
	th {
		width: 20%;
		background: #5ebc61;
		color: white;
		border-right: 1px solid white;
		text-align: center;
		padding: 1em;
		letter-spacing: 0.1em;
	}
}




.building-page__intro-footnote {

}





.building-page__intro-smallprint {
	font-size: 0.8em;
}


@media(max-width: $mobile-viewport){
	.building-page__table {
		font-size: 0.7em;

		tbody td,
		thead th {
			padding: .7em .5em;
		}
	}
}