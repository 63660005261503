.form
  {
    input, select, textarea
    {
      width: 100%;
    }      
    
    .row
    {
      > div
      {
        
        &:nth-child(odd)
        {
          padding-right: 61px;
        }
        
        &:nth-child(even)
        {
          padding-left: 61px;
        }          
      }
    }   
  }








  // THE form found in |SIGN_UP_FORM|
  .sign-up__form {
    padding-left: 15px !important;

    // Move to left on bigger screen
    @media(min-width: $mobile-viewport){
      padding-left: 61px !important;
    }

    // Remove padding from h2 
    @media(max-width: $mobile-viewport){
        h2 { padding: 0 !important; }
    }
  }




.sign-up__text {
  @media(max-width: $mobile-viewport){
    margin-bottom: 3em;
  }
}


.sign-up {
  input {
    padding: 16px;
    font-size: 1.2em;
  }

  button {
    font-size: 1.4em;
    padding: 15px;
  }

  ::-webkit-input-placeholder {
   color: black;
  }

  :-moz-placeholder { /* Firefox 18- */
     color: black;  
  }

  ::-moz-placeholder {  /* Firefox 19+ */
     color: black;  
  }

  :-ms-input-placeholder {  
     color: black;  
  }
}




.sign-up__form input {
  display: block;
  width: 100%;
}