.rocket-fuel
    {
      color: $light;
      background-attachment: scroll !important;
      background-position: bottom center !important;
      
      &:after
      {
        content: '';
        background: $dark;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 0.4;
      }
      
      .slide-inner
      {
        z-index: 1;
        
        h2
        {
          color: $light;
          margin-top: 32px;
        }        
        
        .logo
        {
          width: 297px;
          height: 109px;
          display: block;
          @include background-svg-with-fallback("rocket-fuel-logo");
          background-position: top left;
          background-repeat: no-repeat;
        }        
        
        .columns
        {
          > div
          {
            margin: 32px 0 16px 0;
            width: 60%;
            display: inline-block;
            vertical-align: top;
            
            &.column-2
            {
              text-align: center;
              width: 40%;
              
              .button
              {
                background: #EF4B4B;
                color: $light;
                text-transform: uppercase;
                padding: 8px 16px;
                letter-spacing: 0.32em;
                @include border-radius(3px);
                border: 1px solid $light;
                margin: 24px 0 0 0;
                display: inline-block
              }
            }
          }
        }
      }
    }