.feeling-social {


  &-background {
    background: $green;
  }

  &__list-item {
    display: inline-block;
    height: 4em;
    width: 4em;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &:before { display: none !important; }

    @media(max-width: $mobile-viewport) {
      width: 1.9em;
      height: 1.9em;
    }

    &.facebook { @include background-svg-with-fallback("facebook-light"); }

    &.twitter { @include background-svg-with-fallback("twitter-light"); }
    
    &.youtube { @include background-svg-with-fallback("youtube-light");  }
    
    &.linkedin { @include background-svg-with-fallback("linked-in-light"); }
    
    &.googleplus { @include background-svg-with-fallback("google-light"); }
    
    &.pinterest { @include background-svg-with-fallback("pinterest-light"); }
    
    &.instagram { @include background-svg-with-fallback("instagram-light"); }

    a {
      width: 100%;
      height: 100%;
      display: inline-block;
    }

  }


}