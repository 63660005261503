.people
{
  $people-size: 208px;
  max-width: 584px;
  width: 100%;
  margin: 64px auto 0 auto !important;
  
  li
  {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: center !important;    
    @include box-sizing();
    padding: 0 32px 0 0;
    margin: 0 0 84px 0 !important;
    
    &:nth-child(2n+2)
    {
      padding: 0 0 0 32px;
    }
        
    em
    {
      width: $people-size;
      height: $people-size;
      @include border-radius($people-size/2);
      background-color: $green;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 auto;
      position: relative;
      
      span
      {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        line-height: $people-size;
        font-size: 3.5em;
        color: $light-grey;
        opacity: 0.4;
      }
    }
    
    strong
    {
      display: block;
      text-transform: uppercase;
      letter-spacing: 0.32em;
      color: $green;
      font-size: 1.2em;      
      margin: 32px auto 8px auto;
      height: 105px;
      font-weight: normal;
    }
    
    span
    {
      display: block;
      color: $darkest-grey;
    }
  }
  
  @media(max-width: $mobile-viewport)
  {
    li
    {
      width: 100%;
      padding: 0px;
      
      em
      {
        width: 1.5 * $people-size;
        height: 1.5 * $people-size;
        @include border-radius(1.5 * $people-size);
        
        span
        {
          line-height: 1.5 * $people-size;
        }
      }
    }
  }
}