.banner h1 span {
  @media(max-width: $mobile-viewport) {
    font-size: 0.8em !important;
  }
}

.blog-inner .banner {
  min-height: 480px !important;
}

.banner {
  color: $light;
  background-color: mix($green, $light);

  h1 {
    margin-top: 30px;

    span {
      // SOME STYLING FOR THIS IS IN THE HEAD

      &.visible {
        opacity: 1;
      }
    }
  }

  h1, p {
    text-align: center;
  }

  h1 + p {
    font-size: 1.25em;
    margin-top: 4px;
  }

  .scroll-hint {
    // SOME STYLING FOR THIS IS IN THE HEAD

    &.visible {
      opacity: 1;
      top: 36px;
    }
  }

  &.columned {
    .columns {
      margin-top: 64px;

      > div {
        display: inline-block;
        vertical-align: top;

        &.column-1 {
          width: 65%;
          padding-right: 15%;

          &, & h1 {
            text-align: left;
          }

          h1 {
            margin-top: -16px;
          }
        }

        &.column-2 {
          width: 20%;
        }
      }
    }

    @media(max-width: $mobile-viewport) {
      .columns {
        > div {
          width: 100% !important;
          padding: 0px !important;

          &.column-2 {
            display: none;
          }
        }
      }
    }
  }

}

li.floating-intro-text {
  min-height: 0 !important;
  height: auto !important;
  width: auto !important;
}